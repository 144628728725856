import React from 'react'
import {GrGatsbyjs, GrReactjs} from 'react-icons/gr'

const Footer = () => {

  return (
      <footer className='footer'>
        <div className='container content'>
          
            <div className="made-with">
              <div className="app-logos"><a href="https://www.gatsbyjs.com/" target="_blank" rel="noopener noreferrer"><span className="sr-only">Link to Gatsbyjs.com</span><GrGatsbyjs aria-hidden="true" /> <GrReactjs aria-hidden="true" /></a></div>
              <p>This site was made with Gatsby. A React-based site generator.</p>
            </div>
          
            <div className="copyright">
              <p>&copy;{new Date().getFullYear()} Zachary Joel Fuller. All rights reserved.</p>
            </div>
          
        </div>
      </footer>
  )
}

export default Footer
