import React from "react"
import {graphql} from 'gatsby'
import Helmet from '../../../components/Helmet';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import BLogListing from '../../../components/BlogListing'
import OliveGreenCube from '../../../images/svg/OLIVE_GREEN_cube.inline.svg'

import '../../../components/BlogListingStyles.scss'

const TagTemplate = (props) => {
  
  const posts = props.data.allBlogJson.nodes
  // console.log( props )
  const selectedTag = props.data.tagDataJson

  return (
    <>
		<Helmet title={`Tag Page: `} />
		<Header subNav={true} />

		<main id="tag-page">
			<div className="container">
      <section className="section container">
      <div className="content" id="main-content">
        <section className="blog">
          <div className="blog-header">
            
            <div className="top-title">
                <div>
                  <OliveGreenCube aria-hidden="true" />
                  <h1 className="h1"><span>Tag: </span> <span className="h2">{selectedTag.name}</span></h1>
                </div>
                <p>All the Articles of this Tag</p>
            </div>
            <div className="global-tags tags-header">
              <h2 className="h1"><span></span></h2>
              <div className="work-tags blog-tags">
              
              </div>
            </div>
          </div>
        </section>
            
        <section className="blog-summary">

          {posts.map((post, index)=>{
            return (
              <BLogListing
                key={post.jsonId}
                title={post.title}
                shortDescription={post.shortDescription}
                slug={post.slug}
                thumbnail={post.thumbnail}
              
              />
            )

          })}

        </section>

        </div>
    </section>
    </div>
      
		</main>
		<Footer />
	</>
  )
}

export const query = graphql`
query TagsQuery($slug: String) {
  allBlogJson(filter: {tags: {elemMatch: {slug: {eq: $slug}}}}) {
    totalCount
    nodes {
      jsonId
      title
      tags {
        slug
        name
        definitionDescription
        definition
        class
      }
      shortDescription
      slug
      htmlCopy
      alt
      thumbnail {
        id
        absolutePath
        childImageSharp {
          id
          gatsbyImageData(layout: FIXED, placeholder: TRACED_SVG, width: 155)
        }
      }
    }
  }
  tagDataJson(slug: {eq: $slug}) {
    name
    definition
    definitionDescription
    jsonId
    slug
  }
}
`
export default TagTemplate
